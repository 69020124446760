<template>
  <div>
    <!-- if fetch status is error -->
    <template v-if="fetchStatus === FetchStatus.Error">
      <div class="alert alert-danger" role="alert">
        <p class="error-message-block p-space-md">
          Oops! We are currently experiencing issues retrieving cost information
          for this program. We apologize for the inconvenience, please try back
          later.
        </p>
      </div>
    </template>
    <!-- else -->
    <template v-else>
      <!-- tabs -->
      <ul
        class="tab_nav d-flex mb-0 justify-content-center tab-gap"
        role="tablist"
      >
        <!-- Resident tab -->
        <li class="tab_nav-item">
          <button
            @click="handleActiveTab(ActiveTab.Resident)"
            class="tab_nav-link px-space-xxs pt-space-xxxs"
            data-bs-toggle="tab"
            :class="{
              'tab_nav-link--active': activeTab === ActiveTab.Resident,
            }"
            role="tab"
            aria-controls="tab_resident"
            :aria-selected="activeTab === ActiveTab.Resident"
          >
            Resident tuition
          </button>
        </li>
        <!-- Non-Resident tab -->
        <li class="nav-tab-item">
          <button
            class="tab_nav-link px-space-xxs pt-space-xxxs"
            @click="handleActiveTab(ActiveTab.NonResident)"
            :disabled="fetchStatus === FetchStatus.Loading"
            data-bs-toggle="tab"
            :class="{
              'tab_nav-link--active': activeTab === ActiveTab.NonResident,
            }"
            role="tab"
            aria-controls="tab_non_resident"
            :aria-selected="activeTab === ActiveTab.NonResident"
          >
            Non-Resident tuition
          </button>
        </li>
      </ul>
      <!-- ignore -->
      <div class="tab-content bg-white">
        <template v-if="costOverride">
          <div class="py-space-md text-center">
            <h3 class="h3-small fw-bold mb-space-xs">
              {{ costCollectionCaption }}
            </h3>
            <p class="mb-0 fw-bold h2-large">{{ manualCostAmount }}</p>
          </div>
        </template>

        <template v-else>
          <div class="px-space-xs py-space-md text-center">
            <spinner-group v-if="fetchStatus === FetchStatus.Loading" />
            <template v-else>
              <!-- Resident tab content -->
              <div
                v-if="activeTab === ActiveTab.Resident"
                id="resident_tab"
                class="tab-pane"
                :class="{ 'show active': activeTab === ActiveTab.Resident }"
                role="tabpanel"
                aria-labelledby="resident_tab"
              >
                <div
                  class="d-flex flex-column align-items-center justify-content-center mb-space-sm"
                >
                  <h3
                    class="h3-medium bg-secondary fw-bold py-space-xxxs px-space-xs mb-space-xs"
                  >
                    {{ calculatorTitle }}
                  </h3>
                  <div v-if="isGraduate">
                    <p class="fs-small mb-space-xxxs">Graduate key:</p>
                    <p class="fs-small mb-space-xxxs">
                      9+ credits for full-time
                    </p>
                    <p class="fs-small mb-0">
                      6 credits for part-time or working students
                    </p>
                  </div>
                  <div v-else>
                    <p class="fs-small mb-space-xxxs">Undergraduate key:</p>
                    <p class="fs-small mb-space-xxxs">
                      12+ credits for full-time
                    </p>
                    <p class="fs-small mb-0">
                      6 credits for part-time or working students
                    </p>
                  </div>
                </div>
                <!-- slider -->
                <div
                  class="d-flex align-items-center mb-space-sm border border-2 border-light-3 rounded-pill px-space-xs py-space-xxs slider-border-width"
                >
                  <h3 class="h3-medium fw-bold me-space-xs">
                    {{ residentSlider }}
                  </h3>
                  <rds-slider
                    v-model="residentSlider"
                    min="1"
                    :max="residencyStatusResponse?.max_credits"
                    aria-label="Select number of credit hours per semester"
                    class="flex-fill me-space-xxxs"
                    data-cy="tuition-calculator-credits-slider"
                  ></rds-slider>
                </div>
                <p class="fs-xs mb-space-sm">
                  Drag the slider to see the tuition breakdown by credit hour
                </p>
                <!-- select academic year -->
                <div class="text-start mb-space-sm">
                  <label
                    for="calc-academic-year-res"
                    class="form-label fw-bold mb-space-xxs"
                    >Choose an academic year</label
                  >
                  <TypeAheadSelect
                    id="calc-academic-year-res"
                    :options="academicYearOptions"
                    :clearable="false"
                    @changed="(val) => handleAcademicYearChange(val, 'res')"
                    v-model="selectedResAcademicYear"
                  />
                </div>
                <div
                  class="mb-space-xs d-flex align-items-start justify-content-start justify-content-md-between flex-column flex-md-row"
                >
                  <p class="fs-medium text-start mb-0">Tuition breakdown**</p>
                  <div
                    class="text-dark-3 mt-space-xs mt-md-0"
                    v-if="isResSummerTuitionAvailable"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="include-summer"
                      id="include-summer"
                      v-model="showResSummerTuition"
                      @click="handleIncludeSummerCheckboxClick('res')"
                    />
                    <label for="include-summer" class="fw-bold ms-space-xxs"
                      >Include summer</label
                    >
                  </div>
                </div>
                <div
                  class="accordion"
                  :id="`program_cost_${ActiveTab.Resident?.replaceAll(
                    ' ',
                    '_'
                  )}`"
                >
                  <collapse-cost-item
                    v-for="(data, index) in creditHourCost"
                    :key="index"
                    :id="generateCollapseId('res', data.id)"
                    @collapse-shown="
                      handleCollapseShown(data.title, ActiveTab.Resident)
                    "
                    @collapse-hidden="
                      handleCollapseHidden(data.title, ActiveTab.Resident)
                    "
                    :title="data.title"
                    :amount="data.amount"
                    :items="data.items"
                    collapse-title-size="small"
                    class="mb-space-xxs text-start border-light-4"
                  ></collapse-cost-item>
                </div>
                <!-- more info -->
                <div class="bg-dark-3 text-light-1 px-space-sm py-space-xs">
                  <div
                    class="d-flex justify-content-between align-items-center gap-space-xxs"
                  >
                    <p class="fs-large fw-bold mb-0 text-start">
                      Academic Year Total
                    </p>
                    <p
                      class="fs-large fw-bold mb-0"
                      data-cy="tuition-calculator-total-cost"
                    >
                      {{ residentAcademicYearTotal }}
                    </p>
                  </div>
                  <hr class="hr-bg-color my-space-xs mx-0" />
                  <p class="fs-xs">
                    *This is not a bill. This is only an estimate. Special class
                    fees are dependent on specific class enrollment and are not
                    included here.
                  </p>
                  <p class="fs-xs mb-0">
                    For additional cost estimates, view our
                    <nuxt-link
                      href="https://tuition.asu.edu/cost"
                      @click.native="
                        handleCostLink(
                          'academic year total',
                          'standard cost of attendance'
                        )
                      "
                      class="text-secondary rds-link"
                      >standard cost of attendance</nuxt-link
                    >.
                  </p>
                </div>
              </div>
              <!-- Non-Resident tab content -->
              <div
                class="tab-pane"
                id="non_resident_tab"
                v-else
                :class="{ 'show active': activeTab === ActiveTab.NonResident }"
                role="tabpanel"
                aria-labelledby="non_resident_tab"
              >
                <div
                  class="d-flex flex-column align-items-center justify-content-center mb-space-sm"
                >
                  <h3
                    class="h3-medium bg-secondary fw-bold py-space-xxxs px-space-xs mb-space-xs"
                  >
                    {{ calculatorTitle }}
                  </h3>
                  <div v-if="isGraduate">
                    <p class="fs-small mb-space-xxxs">Graduate key:</p>
                    <p class="fs-small mb-space-xxxs">
                      9+ credits for full-time
                    </p>
                    <p class="fs-small mb-0">
                      6 credits for part-time or working students
                    </p>
                  </div>
                  <div v-else>
                    <p class="fs-small mb-space-xxxs">Undergraduate key:</p>
                    <p class="fs-small mb-space-xxxs">
                      12+ credits for full-time
                    </p>
                    <p class="fs-small mb-0">
                      6 credits for part-time or working students
                    </p>
                  </div>
                </div>
                <!-- slider -->
                <div
                  class="d-flex align-items-center mb-space-sm border border-2 border-light-3 rounded-pill px-space-xs py-space-xxs slider-border-width"
                >
                  <h3 class="h3-medium fw-bold me-space-xs">
                    {{ nonResidentSlider }}
                  </h3>
                  <rds-slider
                    v-model="nonResidentSlider"
                    min="1"
                    :max="nonResidencyStatusResponse?.max_credits"
                    aria-label="Select number of credit hours per semester"
                    class="flex-fill me-space-xxxs"
                    data-cy="tuition-calculator-credits-slider"
                  ></rds-slider>
                </div>

                <p class="fs-xs mb-space-sm">
                  Drag the slider to see the tuition breakdown by credit hour
                </p>
                <!-- select academic year -->
                <div class="text-start mb-space-sm">
                  <label
                    for="calc-academic-year-nores"
                    class="form-label fw-bold mb-space-xxs"
                    >Choose an academic year</label
                  >
                  <TypeAheadSelect
                    id="calc-academic-year-nores"
                    :options="academicYearOptions"
                    :clearable="false"
                    @changed="(val) => handleAcademicYearChange(val, 'nores')"
                    v-model="selectedNoresAcademicYear"
                  />
                </div>
                <div
                  class="mb-space-xs d-flex align-items-start justify-content-start justify-content-md-between flex-column flex-md-row"
                >
                  <p class="fs-medium text-start mb-0">Tuition breakdown**</p>
                  <div
                    class="text-dark-3 mt-space-xs mt-md-0"
                    v-if="isNoresSummerTuitionAvailable"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="include-summer"
                      id="include-summer"
                      v-model="showNoresSummerTuition"
                      @click="handleIncludeSummerCheckboxClick('nores')"
                    />
                    <label for="include-summer" class="fw-bold ms-space-xxs"
                      >Include summer</label
                    >
                  </div>
                </div>
                <div
                  class="accordion"
                  :id="`program_cost_${ActiveTab.NonResident?.replaceAll(
                    ' ',
                    '_'
                  )}`"
                >
                  <collapse-cost-item
                    v-for="(data, index) in creditHourCostNonResident"
                    :key="index"
                    :id="generateCollapseId('res', data.id)"
                    :title="data.title"
                    @collapse-shown="
                      handleCollapseShown(data.title, ActiveTab.NonResident)
                    "
                    @collapse-hidden="
                      handleCollapseHidden(data.title, ActiveTab.NonResident)
                    "
                    :amount="data.amount"
                    :items="data.items"
                    collapse-title-size="small"
                    class="mb-space-xxs text-start border-light-4"
                  ></collapse-cost-item>
                </div>
                <!-- more info -->
                <div class="bg-dark-3 text-light-1 px-space-sm py-space-xs">
                  <div
                    class="d-flex justify-content-between align-items-center gap-space-xxs"
                  >
                    <p class="fs-large fw-bold mb-0 text-start">
                      Academic Year Total
                    </p>
                    <p
                      class="fs-large fw-bold mb-0"
                      data-cy="tuition-calculator-total-cost"
                    >
                      {{ nonresidentAcademicYearTotal }}
                    </p>
                  </div>
                  <hr class="hr-bg-color my-space-xs mx-0" />
                  <p class="fs-xs">
                    *This is not a bill. This is only an estimate. Special class
                    fees are dependent on specific class enrollment and are not
                    included here.
                  </p>
                  <p class="fs-xs mb-0">
                    For additional cost estimates, view our
                    <nuxt-link
                      href="https://tuition.asu.edu/cost"
                      @click.native="
                        handleCostLink(
                          'academic year total',
                          'standard cost of attendance'
                        )
                      "
                      class="text-secondary rds-link"
                      >standard cost of attendance</nuxt-link
                    >.
                  </p>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { UseTuitionApi } from "@/composables/useTuitionApi";
import {
  AsuTuitionApiResponseData,
  AsuTuitionApiResponseDataFeeBreakdown,
  TermCreditHourCostData,
} from "@/types/types";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import RdsSlider from "~/components/RdsSlider.vue";
import SpinnerGroup from "~/components/SpinnerGroup.vue";
import TypeAheadSelect, { OptionType } from "~/components/TypeAheadSelect.vue";
import CollapseCostItem from "~/components/degree-page/CollapseCostItem.vue";
import CostService from "~/src/services/CostService";

enum FetchStatus {
  Loading = "loading",
  Error = "error",
  Success = "success",
  Ignore = "ignore",
}
enum ActiveTab {
  Resident = "resident tuition",
  NonResident = "non-resident tuition",
}
type Props = {
  programCategory: string;
  costOverride: boolean;
  costAcademicYear: string;
  programCode: string;
  feeCodeResident: string;
  feeCodeNonResident: string;
  costCollection: {
    amount: number;
    summary: string | null;
  } | null;
  degreeType: "Graduate" | "Undergraduate" | "Certificate";
  isMountedModal: boolean;
};
const calculatorTitle = "Your tuition estimate";
/*
 ********************************************
 * Props
 ********************************************
 */
const props = defineProps<Props>();

type TuitionApiResponseDataRawCollection = {
  [key: string]: AsuTuitionApiResponseData;
};

/*
 ********************************************
 * Data
 ********************************************
 */
const fetchStatus = ref<FetchStatus>(FetchStatus.Ignore);
const activeTab = ref<ActiveTab>(ActiveTab.Resident);
const residencyStatusResponse = ref<AsuTuitionApiResponseData | null>(null);
const nonResidencyStatusResponse = ref<AsuTuitionApiResponseData | null>(null);
// newlyAdded
const apiResponseResCollection =
  ref<TuitionApiResponseDataRawCollection | null>(null);
const apiResponseNoresCollection =
  ref<TuitionApiResponseDataRawCollection | null>(null);
const academicYearOptions = ref<OptionType[]>([]);
const academicYearUnformattedOptions = ref<number[]>([]);
const selectedResAcademicYear = ref<OptionType | null>(null);
const selectedNoresAcademicYear = ref<OptionType | null>(null);
const selectedUnformattedResAcademicYear = ref<number | null>(null);
const selectedUnformattedNoresAcademicYear = ref<number | null>(null);
const residentSlider = ref("6");
const creditHourCost = ref<TermCreditHourCostData[]>([]);
const creditHourCostNonResident = ref<TermCreditHourCostData[]>([]);
const nonResidentSlider = ref("12");
const nonresidentAcademicYearTotal = ref<string>("");
const residentAcademicYearTotal = ref<string>("");
const isResSummerTuitionAvailable = ref<boolean>(false);
const isNoresSummerTuitionAvailable = ref<boolean>(false);
const showResSummerTuition = ref<boolean>(false);
const showNoresSummerTuition = ref<boolean>(false);
/*
 ********************************************
 * Computed
 ********************************************
 */
const manualCostAmount = computed(() => {
  return `$${props.costCollection?.amount!.toLocaleString()}`;
});

const costCollectionCaption = computed(() => {
  let caption = "Cost caption";
  if (props.programCategory === "Graduate") {
    caption = "The cost of this program is:";
  } else if (props.programCategory === "Certificates") {
    caption = "The cost per credit hour for this program is:";
  }
  return caption;
});

const isGraduate = computed(() => {
  return props.degreeType === "Graduate";
});

/*
 ********************************************
 * Methods
 ********************************************
 */
const handleActiveTab = async (tab: ActiveTab) => {
  activeTab.value = tab;
  if (tab === ActiveTab.NonResident) {
    await fetchNoResData();
  }
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    calculatorTitle.toLowerCase(),
    tab.toLowerCase(),
    "asu online tuition calculator"
  );
};

const initTuitionApi = async () => {
  const {
    costAcademicYear,
    programCode,
    degreeType,
    feeCodeResident,
    feeCodeNonResident,
  } = props;

  const tuitionApi = new UseTuitionApi(
    costAcademicYear,
    degreeType === "Undergraduate" ? "UGRD" : "GRAD",
    programCode,
    feeCodeResident,
    feeCodeNonResident
  );
  await tuitionApi.checkNewestAcademicYear();
  return tuitionApi;
};
const setAcademicYearOptions = (acadYears: string[]) => {
  selectedUnformattedResAcademicYear.value =
    academicYearUnformattedOptions.value[0];
  selectedUnformattedNoresAcademicYear.value =
    academicYearUnformattedOptions.value[0];

  academicYearOptions.value = acadYears.map((item, idx) => {
    return {
      text: item,
      value: academicYearUnformattedOptions.value[idx].toString(),
    };
  });
  // set initial selected academic year option for both res/nores tabs
  if (academicYearUnformattedOptions.value.length === 3) {
    selectedResAcademicYear.value = {
      text: acadYears[1],
      value: academicYearUnformattedOptions.value[1].toString(),
    };
    selectedNoresAcademicYear.value = {
      text: acadYears[1],
      value: academicYearUnformattedOptions.value[1].toString(),
    };
  } else {
    selectedResAcademicYear.value = {
      text: acadYears[0],
      value: academicYearUnformattedOptions.value[0].toString(),
    };
    selectedNoresAcademicYear.value = {
      text: acadYears[0],
      value: academicYearUnformattedOptions.value[0].toString(),
    };
  }
};

const fetchResData = async () => {
  // prevent making API call when costOverride is set as true
  if (props.costOverride) return;
  // prevent making API call when content is alread mounted on the component
  if (residencyStatusResponse.value !== null) return;

  const tuitionApi = await initTuitionApi();
  const academicYears = tuitionApi.getAcademicYearText();
  academicYearUnformattedOptions.value = tuitionApi.academicYears;
  setAcademicYearOptions(academicYears);
  const res = await tuitionApi.fetchResCostInfo();

  // initial default info based on of API response
  for (let idx = 0; idx < res.length; idx++) {
    if (!res[idx].data.value) {
      fetchStatus.value = FetchStatus.Error;
      return;
    }
    if (academicYearUnformattedOptions.value.length === 3 && idx === 1) {
      residencyStatusResponse.value = res[idx]?.data.value?.data!;
      const responseData = res[idx]?.data.value?.data;
      if (responseData) {
        calCreditHourCost(responseData);
      }
    }
    if (academicYearUnformattedOptions.value.length !== 3 && idx === 0) {
      residencyStatusResponse.value = res[idx]?.data.value?.data!;
      const responseData = res[idx]?.data.value?.data;
      if (responseData) {
        calCreditHourCost(responseData);
      }
    }
  }
  // updated res data collection
  apiResponseResCollection.value = academicYearUnformattedOptions.value.reduce(
    (acc, year, idx) => {
      acc[year.toString()] = res[idx].data.value?.data!;
      return acc;
    },
    {} as Record<string, AsuTuitionApiResponseData>
  );
  fetchStatus.value = FetchStatus.Success;
};
const fetchNoResData = async () => {
  // prevent making API call when costOverride is set as true
  if (props.costOverride) return;
  // prevent making API call when content is alread mounted on the component
  if (nonResidencyStatusResponse.value !== null) return;
  // set FetchStatus to Loading if nonResidencyStatusResponse is null for initial data fetch
  fetchStatus.value = FetchStatus.Loading;
  const tuitionApi = await initTuitionApi();
  const res = await tuitionApi.fetchNoresCostInfo();

  // initial data setup based on API response of nores
  for (let idx = 0; idx < res.length; idx++) {
    if (!res[idx].data.value) {
      fetchStatus.value = FetchStatus.Error;
      return;
    }
    if (academicYearUnformattedOptions.value.length === 3 && idx === 1) {
      nonResidencyStatusResponse.value = res[idx]?.data.value?.data!;
      const responseData = res[idx]?.data.value?.data;
      if (responseData) {
        calCreditHourCostNonResident(responseData);
      }
    }
    if (academicYearUnformattedOptions.value.length !== 3 && idx === 0) {
      nonResidencyStatusResponse.value = res[idx]?.data.value?.data!;
      const responseData = res[idx]?.data.value?.data;
      if (responseData) {
        calCreditHourCostNonResident(responseData);
      }
    }
  }
  // updated data collections
  apiResponseNoresCollection.value =
    academicYearUnformattedOptions.value.reduce((acc, year, idx) => {
      acc[year.toString()] = res[idx].data.value?.data!;
      return acc;
    }, {} as Record<string, AsuTuitionApiResponseData>);
  fetchStatus.value = FetchStatus.Success;
};

const setUnformattedResAcademicYearOptions = (arg: string) => {
  selectedUnformattedResAcademicYear.value = parseInt(arg);
};
const setUnformattedNoresAcademicYearOptions = (arg: string) => {
  selectedUnformattedNoresAcademicYear.value = parseInt(arg);
};

const handleAcademicYearChange = (
  val: OptionType | null,
  resStatus: "res" | "nores"
) => {
  if (val === null) return;
  if (val.value === null) return;
  if (typeof selectedResAcademicYear.value?.text === "undefined") return;
  if (typeof selectedNoresAcademicYear.value?.text === "undefined") return;
  // invoke only in res
  if (resStatus === "res") {
    setUnformattedResAcademicYearOptions(val.value);
    residencyStatusResponse.value = apiResponseResCollection.value![val.value];
    if (residencyStatusResponse.value) {
      calCreditHourCost(residencyStatusResponse.value);
    }
  }
  // invoke only in nores
  if (resStatus === "nores") {
    setUnformattedNoresAcademicYearOptions(val.value);
    nonResidencyStatusResponse.value =
      apiResponseNoresCollection.value![val.value];
    if (nonResidencyStatusResponse.value) {
      calCreditHourCostNonResident(nonResidencyStatusResponse.value);
    }
  }
  const academicYear: string =
    resStatus === "res"
      ? selectedResAcademicYear.value?.text.toLowerCase()
      : selectedNoresAcademicYear.value?.text.toLowerCase();

  analyticsComposable.trackSelectEvent(
    "onclick",
    "click",
    "choose an academic year",
    "main content",
    "your tuition estimate",
    academicYear,
    resStatus === "res" ? "resident tuition" : "non-resident tuition"
  );
};
const generateCollapseId = (prefix: string, id: string) => {
  return `${prefix}_${id}`;
};
const calCreditHourCost = (
  residencyStatusResponse: AsuTuitionApiResponseData
) => {
  creditHourCost.value = Object.values(
    CostService.generateResidencyCostData(
      selectedUnformattedResAcademicYear.value!.toString(),
      residencyStatusResponse,
      residentSlider.value! as keyof Omit<
        AsuTuitionApiResponseDataFeeBreakdown,
        "descr"
      >,
      showResSummerTuition.value
    )
  ) as TermCreditHourCostData[];
  residentAcademicYearTotal.value = `$${CostService.calculateAcademicYearTotal(
    residencyStatusResponse,
    residentSlider.value! as keyof Omit<
      AsuTuitionApiResponseDataFeeBreakdown,
      "descr"
    >,
    showResSummerTuition.value
  ).toLocaleString()}`;
};
const calCreditHourCostNonResident = (
  nonResidencyStatusResponse: AsuTuitionApiResponseData
) => {
  creditHourCostNonResident.value = Object.values(
    CostService.generateResidencyCostData(
      selectedUnformattedNoresAcademicYear.value!.toString(),
      nonResidencyStatusResponse,
      nonResidentSlider.value! as keyof Omit<
        AsuTuitionApiResponseDataFeeBreakdown,
        "descr"
      >,
      showNoresSummerTuition.value
    )
  ) as TermCreditHourCostData[];
  nonresidentAcademicYearTotal.value = `$${CostService.calculateAcademicYearTotal(
    nonResidencyStatusResponse,
    nonResidentSlider.value! as keyof Omit<
      AsuTuitionApiResponseDataFeeBreakdown,
      "descr"
    >,
    showNoresSummerTuition.value
  ).toLocaleString()}`;
};
const handleIncludeSummerCheckboxClick = (type: "res" | "nores") => {
  let action: string;
  if (type === "res") {
    action = showResSummerTuition.value ? "unclick" : "click";
  } else {
    action = showNoresSummerTuition.value ? "unclick" : "click";
  }
  analyticsComposable.trackSelectEvent(
    "onclick",
    action,
    "checkbox",
    "main content",
    "your tuition estimate",
    "include summer"
  );
};
const handleCollapseShown = (text: string, component: string) => {
  analyticsComposable.trackCollapseEvent(
    "onclick",
    "open",
    "click",
    "main content",
    calculatorTitle.toLowerCase(),
    text.toLowerCase(),
    component.toLowerCase()
  );
};
const handleCollapseHidden = (text: string, component: string) => {
  analyticsComposable.trackCollapseEvent(
    "onclick",
    "close",
    "click",
    "main content",
    calculatorTitle.toLowerCase(),
    text.toLowerCase(),
    component.toLowerCase()
  );
};
const handleCostLink = (text: string, component: string) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    text.toLowerCase(),
    component.toLowerCase(),
    calculatorTitle.toLowerCase()
  );
};
/*
 ********************************************
 * Lifecycle
 ********************************************
 */

onMounted(() => {
  if (props.costOverride) {
    // added main fetch status
    fetchStatus.value = FetchStatus.Ignore;
  } else {
    fetchStatus.value = FetchStatus.Loading;
  }
});

defineExpose<{
  fetchCostApi: () => Promise<void>;
}>({
  fetchCostApi: fetchResData,
});

watch(residentSlider, () => {
  if (residencyStatusResponse.value) {
    calCreditHourCost(residencyStatusResponse.value);
  }
});
watch(nonResidentSlider, () => {
  if (nonResidencyStatusResponse.value) {
    calCreditHourCostNonResident(nonResidencyStatusResponse.value);
  }
});

/**
 * @summary watchers for residency|non-residency status response to decide whether summer tuition checkbox component should be displayed or not. (FS0005 for UG, FS0008 for GR). resets showSummerTuition value (default=false) on value change (prevent empty collapse component being rendered).
 */
watch(residencyStatusResponse, (newVal) => {
  if (newVal !== undefined && newVal !== null) {
    isResSummerTuitionAvailable.value =
      "FS0005" in newVal.breakdown.summer ||
      "FS0008" in newVal.breakdown.summer;
    showResSummerTuition.value = false;
  }
});
watch(nonResidencyStatusResponse, (newVal) => {
  if (newVal !== undefined && newVal !== null) {
    isNoresSummerTuitionAvailable.value =
      "FS0005" in newVal.breakdown.summer ||
      "FS0008" in newVal.breakdown.summer;
    showNoresSummerTuition.value = false;
  }
});
/**
 * @summary watch showSummerTuition ref values and recalculate credit hour cost based on its value.
 */
watch(showResSummerTuition, () => {
  if (residencyStatusResponse.value) {
    calCreditHourCost(residencyStatusResponse.value);
  }
});
watch(showNoresSummerTuition, () => {
  if (nonResidencyStatusResponse.value) {
    calCreditHourCostNonResident(nonResidencyStatusResponse.value);
  }
});
</script>

<style scoped lang="scss">
.tab_nav {
  border-bottom: 1px solid var(--rds--light-4);
  list-style: none;
  &-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: var(--rds--dark-3);
    padding: 0 0 4px;
    font-size: 16px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
    &--active {
      color: var(--rds--dark-3, #191919);
      background-color: var(--rds-white);
      border-color: var(--rds-gray-300) var(--rds-gray-300) var(--rds-white);
    }
  }
}
.tab-gap {
  gap: 1rem;
  padding: 0 12px;
}
.form-check-input:checked {
  background-color: var(--rds-secondary);
  border-color: var(--rds-secondary);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
